import {
  GET_ARTISTS,
  ADD_ARTIST,
  DELETE_ARTIST,
  SET_CURRENT,
  CLEAR_CURRENT,
  UPDATE_ARTIST,
  FILTER_ARTISTS,
  CLEAR_FILTER,
  ARTIST_ERROR,
  CLEAR_ARTISTS,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_ARTISTS:
      return {
        ...state,
        artists: action.payload,
        artworksloading:false,

      };
    case ADD_ARTIST:
      return {
        ...state,
        artists: [action.payload, ...state.artists],
        artworksloading:false,

      };
    case UPDATE_ARTIST:
      return {
        ...state,
        artists: state.artists?.map((artist) =>
          artist?._id === action.payload._id ? action.payload : artist
        ),
        artworksloading:false,

      };
    case DELETE_ARTIST:
      return {
        ...state,
        artists: state.artists.filter(
          (artist) => artist?._id !== action.payload
        ),
        artworksloading:false,

      };
    case CLEAR_ARTISTS:
      return {
        ...state,
        artists: null,
        filtered: null,
        error: null,
        current: null,
      };
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };

    case CLEAR_CURRENT:
      return {
        ...state,
        current: null,
      };
    case FILTER_ARTISTS:
      return {
        ...state,
        filtered: state.artists.filter((artist) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return artist.name.match(regex);
        }),
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filtered: null,
      };
    case ARTIST_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
