import styled from "styled-components";
import breakpoints from "../essentials/screensize";

const HeaderText = styled.div`
  font-family: Brush Script MT;
  color: #01337e;
  text-align: left;
  .one {
    font-size: 50px;
  }
  .two {
    font-size: 150px;
    margin-top: -8vh;
  }
  @media (max-width: ${breakpoints.sm}px) {
    .one {
      font-size: 30px;
    }
    .two {
      font-size: 60px;
      margin-top: -3vh;
    }
  }
`;

export default HeaderText;
