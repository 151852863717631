import styled from "styled-components";
import breakpoints from "../essentials/screensize";

const InfoCard = styled.div`
  // height: 45vh;
  width: 45vh;
  margin: 0rem;
  margin-left: auto;
  padding-inline: 1vh;
  padding-block: 1vh;
  border: 2vh solid white;
  box-shadow: inset 0px 4px 7px rgba(0, 0, 5, 0.4);
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  @media (max-width: ${breakpoints.sm}px) {
    // height: 42vh;
    width: 37vh;
    margin: auto; 
    padding:0.8vh;
  }
`;

export default InfoCard;
