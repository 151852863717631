//Packages
import React from "react";
//Components
import colors from "../essentials/colors";
import { Typography } from "@mui/material";

const HeadingBanner = ({ label }) => {
  return (
    <div
      style={{
        backgroundColor: `${colors.primary}`,
        padding: "1rem",
        width: "100%",
      }}
    >
      <Typography
        sx={{
          color: "white",
          fontFamily: "Brush Script MT",
          fontSize: { xs: "20px", md: "34px" },
        }}
      >
        {label}
      </Typography>
    </div>
  );
};

export default HeadingBanner;
