//Packages
import React from "react";
import Grid from "@mui/material/Grid";
import { useLocation } from "react-router-dom";
import CardMedia from "@mui/material/CardMedia";
//Components
import HeadingBanner from "../components/HeadingBanner";
import LordGaneshaExhibition from "./LordGaneshaExhibition";
//Assets
import eventimg from "../assets/thecolorist_bg.jpg";
import { Typography } from "@mui/material";
const EventInfo = () => {
  const location = useLocation();
  let eventtheme = location?.state;
  return (
    <Grid container columns={12} pt={12}>
      {eventtheme == "Vasantotsav Festival" && (
        <>
          <Grid item md={6} xs={12}>
            <HeadingBanner label={eventtheme} />
            <Typography style={{ paddingInline: "1.5rem", marginTop: "2%" }}>
              <b style={{ fontSize: "20px" }}>
                Hand-painted man-made bee hives
              </b>{" "}
              created by “The Colorist” Team for Jyoti Gramodyog Sansthan in
              Saharanpur. These bee hives were displayed and installed at the{" "}
              <b style={{ fontSize: "20px" }}>
                Raj Bhawan in Dehradun, Uttarakhand,{" "}
              </b>
              during the
              <b style={{ fontSize: "20px" }}> Vasantotsav Festival </b>held
              from March 3rd to March 5th, 2023.
              <br /> <br />
              This event creates a celebration of art, craftsmanship, and
              environmental awareness, showcasing the creative efforts of Jyoti
              Gramodyog Sansthan in producing unique and visually appealing bee
              hives. Displaying them at such a prestigious location as the Raj
              Bhawan during the Vasantotsav Festival likely provided a great
              platform to raise awareness about the importance of bee
              conservation and sustainable practices.
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            sx={{ paddingRight: { xs: "3%", md: "3%" } }}
            mb={4}
            xs={12}
          >
            <Grid container columns={12}>
              <Grid item md={6} xs={6}>
                <CardMedia
                  component="img"
                  sx={{
                    width: { xs: "100%", md: "38vh" },
                    height: { xs: "20vh", md: "38vh" },
                    marginLeft: "1rem",
                    padding: "0.2em",
                    border: "0.5rem solid white",
                    boxShadow: "inset 0px 4px 4px rgba(0, 0, 5, 0.4)",
                objectFit:'contain'

                  }}
                  className="hovericon"
                  image="https://res.cloudinary.com/dak47umlr/image/upload/v1691474336/Picture15_v1dzxb.png"
                  alt={eventimg}
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <CardMedia
                  component="img"
                  sx={{
                    width: { xs: "100%", md: "38vh" },
                    height: { xs: "20vh", md: "38vh" },
                    marginLeft: "auto",
                    padding: "0.2em",
                    border: "0.5rem solid white",
                    boxShadow: "inset 0px 4px 4px rgba(0, 0, 5, 0.4)",
                objectFit:'contain'

                  }}
                  className="hovericon"
                  image="https://res.cloudinary.com/dak47umlr/image/upload/v1691474338/Picture9_idinwp.png"
                  alt={eventimg}
                />
              </Grid>
            </Grid>
            <Grid container columns={12} mt={2}>
              <Grid item md={4} xs={4}>
                <CardMedia
                  component="img"
                  sx={{
                    width: { xs: "15vh", md: "25vh" },
                    height: { xs: "15vh", md: "25vh" },
                    marginLeft: "auto",
                    padding: "0.2em",
                    border: "0.5rem solid white",
                    boxShadow: "inset 0px 4px 4px rgba(0, 0, 5, 0.4)",
                objectFit:'contain'

                  }}
                  className="hovericon"
                  image="https://res.cloudinary.com/dak47umlr/image/upload/v1691474336/Picture10_rciken.png"
                  alt={eventimg}
                />
              </Grid>
              <Grid item md={4} xs={4}>
                <CardMedia
                  component="img"
                  sx={{
                    width: { xs: "15vh", md: "25vh" },
                    height: { xs: "15vh", md: "25vh" },
                    marginLeft: "auto",
                    padding: "0.2em",
                    border: "0.5rem solid white",
                    boxShadow: "inset 0px 4px 4px rgba(0, 0, 5, 0.4)",
                objectFit:'contain'

                  }}
                  className="hovericon"
                  image="https://res.cloudinary.com/dak47umlr/image/upload/v1691474336/Picture17_gsmigo.png"
                  alt={eventimg}
                />
              </Grid>
              <Grid item md={4} xs={4}>
                <CardMedia
                  component="img"
                  sx={{
                    width: { xs: "15vh", md: "25vh" },
                    height: { xs: "15vh", md: "25vh" },
                    marginLeft: "auto",
                    padding: "0.2em",
                    border: "0.5rem solid white",
                    boxShadow: "inset 0px 4px 4px rgba(0, 0, 5, 0.4)",
                objectFit:'contain'

                  }}
                  className="hovericon"
                  image="https://res.cloudinary.com/dak47umlr/image/upload/v1691474335/Picture16_iulb5x.png"
                  alt={eventimg}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {eventtheme == "Lord Ganesha's Paintings Exhibition" && <LordGaneshaExhibition eventtheme={eventtheme} />}
    </Grid>
  );
};

export default EventInfo;
