export const SET_CURRENT = "SET_CURRENT";
export const CLEAR_CURRENT = "CLEAR_CURRENT";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const GET_ARTWORKS = "GET_ARTWORKS";
export const ADD_ARTWORK = "ADD_ARTWORK";
export const DELETE_ARTWORK = "DELETE_ARTWORK";
export const UPDATE_ARTWORK = "UPDATE_ARTWORK";
export const FILTER_ARTWORKS = "FILTER_ARTWORKS";
export const ARTWORK_ERROR = "ARTWORK_ERROR";
export const CLEAR_ARTWORKS = "CLEAR_ARTWORKS";

export const GET_ARTISTS = "GET_ARTISTS";
export const ADD_ARTIST = "ADD_ARTIST";
export const DELETE_ARTIST = "DELETE_ARTIST";
export const UPDATE_ARTIST = "UPDATE_ARTIST";
export const FILTER_ARTISTS = "FILTER_ARTISTS";
export const ARTIST_ERROR = "ARTIST_ERROR";
export const CLEAR_ARTISTS = "CLEAR_ARTISTS";
