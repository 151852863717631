import {
  GET_ARTWORKS,
  ADD_ARTWORK,
  DELETE_ARTWORK,
  SET_CURRENT,
  CLEAR_CURRENT,
  UPDATE_ARTWORK,
  FILTER_ARTWORKS,
  CLEAR_FILTER,
  ARTWORK_ERROR,
  CLEAR_ARTWORKS,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_ARTWORKS:
      return {
        ...state,
        artworks: action.payload,
        loading: false,
      };
    case ADD_ARTWORK:
      return {
        ...state,
        artworks: [action.payload, ...state.artworks],
        loading: false,
      };
    case UPDATE_ARTWORK:
      return {
        ...state,
        artworks: state.artworks?.map((artwork) =>
          artwork?._id === action.payload._id ? action.payload : artwork
        ),
        loading: false,
      };
    case DELETE_ARTWORK:
      return {
        ...state,
        artworks: state.artworks.filter(
          (artwork) => artwork?._id !== action.payload
        ),
        loading: false,
      };
    case CLEAR_ARTWORKS:
      return {
        ...state,
        artworks: null,
        filtered: null,
        error: null,
        current: null,
      };
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };

    case CLEAR_CURRENT:
      return {
        ...state,
        current: null,
      };
    case FILTER_ARTWORKS:
      return {
        ...state,
        filtered: state.artworks.filter((artwork) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return artwork.art_title.match(regex);
        }),
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filtered: null,
      };
    case ARTWORK_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
