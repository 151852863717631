import React from "react";
import buttonimg from "../assets/button.png";
import Typography from "@mui/material/Typography";

const NavButton = ({ label }) => {
  return (
    <div
      style={{
        backgroundImage: `url( ${buttonimg})`,
        height: "3.4rem",
        width: "7rem",
        backgroundSize: "cover",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        padding: "auto",
        color:'black'
      }}
    >
      <Typography
        variant="body1"
        align="center"
        style={{
          fontSize: "1rem",
          paddingBottom: "0.7rem",
          position: "relative",
        }}
      >
        {label}
      </Typography>
    </div>
  );
};

export default NavButton;
