//Packages
import React, { useContext, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CardMedia from "@mui/material/CardMedia";
import Carousel from "react-material-ui-carousel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
//Components
import ProgressiveImage from "../components/ProgressiveImage";
import HeadingBanner from "../components/HeadingBanner";
import InfoCard from "../components/InfoCard";
import colors from "../essentials/colors";
//Assets
import loader from "../assets/loader.gif";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
// Context
import ArtworkContext from "../context/artwork/artworkContext";
import ArtistContext from "../context/artist/artistContext";
import Spinner from "../components/Spinner";

const ArtworkInfo = () => {
  const [artwork, setArtwork] = useState();
  let navigate = useNavigate();
  let urlparam = useParams();
  const artworkContext = useContext(ArtworkContext);
  const { getArtworks, artworks } = artworkContext;
  const artistContext = useContext(ArtistContext);
  const { getArtists, artists } = artistContext;
  const [visibleArtworks, setVisibleArtworks] = useState([]);
  let same_artworks = [];
  const [artistinfo, setArtistInfo] = useState();
  const [showAll, setShowAll] = useState(false);
  const LongText = ({ content, limit }) => {
    const showMore = () => setShowAll(true);
    const showLess = () => setShowAll(false);

    if (content?.length <= limit) {
      // there is nothing more to show
      return { content };
    }
    if (showAll) {
      return (
        <>
          {content?.length !== undefined && content}
          {content?.length !== undefined && (
            <small
              style={{ color: "#757575", cursor: "pointer" }}
              onClick={() => showLess()}
            >
              Read less
            </small>
          )}
        </>
      );
    }
    // In the final case, we show a text with ellipsis and a `Read more` button
    const toShow = content?.substring(0, limit) + "...";
    return (
      <>
        {content?.length !== undefined && toShow}
        {content?.length !== undefined && (
          <small
            style={{ color: "#757575", cursor: "pointer" }}
            onClick={() => showMore()}
          >
            Read more
          </small>
        )}
      </>
    );
  };
  const findArtist = async () => {
    artists?.map(
      (artist) => artwork?.artist == artist?._id && setArtistInfo(artist)
    );
  };
  const findSimilarArtworks = async () => {
    await artworks?.map(
      async (art) =>
        art?.art_title == artwork?.art_title && (await same_artworks.push(art))
    );
    if (same_artworks?.length > 0) {
      // const slicedArtworks = await same_artworks?.slice(0, 10); // Limit to the first 5 artworks
      await setVisibleArtworks(same_artworks);
    }
  };
  const renderCarouselItems = () => {
    const isMobileView = window.innerWidth < 600;
    const itemsToShow = isMobileView ? 1 : 4;

    return visibleArtworks?.map((artist, index, array) => {
      const artworksToRender = [...array, ...array.slice(0, itemsToShow - 1)];

      return (
        <Grid
          key={index}
          container
          columns={12}
          display="flex"
          justifyContent="space-evenly"
        >
          {artworksToRender
            ?.slice(index, index + itemsToShow)
            .map((artworkItem, subIndex) => (
              <Grid
                key={subIndex}
                item
                xs={12}
                md={2}
                display="flex"
                className="matchingImg"
                m="auto"
                justifyContent="center"
                onClick={() =>
                  navigate(`/artwork/${artworkItem?._id}`, {
                    state: artworkItem,
                  })
                }
                style={{ cursor: "pointer" }}
              >
                <ProgressiveImage
                  placeholder={loader}
                  src={artworkItem?.art_img}
                  alt={artworkItem?.art_img}
                />
              </Grid>
            ))}
        </Grid>
      );
    });
  };
  useEffect(() => {
    getArtworks();
    getArtists();
    artworks?.map((art) => art?._id == urlparam?.id && setArtwork(art));
  }, [artworks?.length, visibleArtworks?.length, urlparam]);
  useEffect(() => {
    findArtist();
    findSimilarArtworks();
  }, [artists]);

  return (
    <Grid
      container
      columns={12}
      display="flex"
      sx={{ paddingTop: { xs: "0vh", md: "15vh" } }}
    >
      {artwork ? (
        <>
          <Grid
            container
            columns={12}
            sx={{
              minHeight: "fit-content",
            }}
          >
            <Grid
              item
              md={6}
              xs={12}
              sx={{ marginTop: { xs: "10vh", md: "0%" } }}
            >
              <HeadingBanner label={artwork?.art_title} />
              <Grid
                item
                md={12}
                xs={12}
                display="flex"
                p={2}
                sx={{ flexDirection: "column" }}
              >
                {artwork?.art_desc && (
                  <Typography variant="body2" style={{ marginBottom: "3vh" }}>
                    <LongText content={artwork?.art_desc} limit={50} />
                  </Typography>
                )}
                <Typography variant="body2">
                  Artist: {artistinfo?.name}
                </Typography>
                <small>Size : {artwork?.size} </small>
                <small>Medium : {artwork?.art_type} </small>
                {/* <small>Cost : ₹ {artwork?.cost} /-</small> */}
                {artwork?.base && <small>Base: {artwork?.base} </small>}
                {artwork?.status == "sold" && (
                  <small>
                    Status:
                    <small
                      style={{
                        backgroundColor: colors.primary,
                        paddingBlock: "0.3%",
                        paddingInline: "1%",
                        borderRadius: 4,
                        color: "whitesmoke",
                      }}
                    >
                      {artwork?.status?.charAt(0).toUpperCase()}
                      {artwork?.status?.slice(1)}
                    </small>
                  </small>
                )}
                <small style={{ color: colors.primary, marginTop: "5%" }}>
                  <i>
                    You can{" "}
                    <a href="/contact-us">
                      <b>contact us</b>
                    </a>{" "}
                    for commissioned artworks
                  </i>
                </small>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PhoneAndroidIcon fontSize="small" />
                  <small style={{ marginLeft: "0.2rem" }}>+91 9602902907</small>
                </div>
              </Grid>
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
              sx={{
                paddingRight: {
                  xs: "0rem",
                  md: "5rem",
                },
                paddingLeft: {
                  xs: "0rem",
                  md: "auto",
                },
                textAlign: {
                  xs: "center",
                  md: "right",
                },
              }}
            >
              <InfoCard>
                <CardMedia
                  component="img"
                  image={artwork?.art_img}
                  alt={artwork?.art_img}
                  height="auto"
                  style={{ width: "100%" }}
                />
              </InfoCard>
            </Grid>
          </Grid>

          {visibleArtworks?.length > 1 && (
            <Grid item md={12} xs={12} mb={2}>
              <h3 style={{ marginLeft: "3vw", paddingTop: "1vh" }}>
                Artworks similar like this
              </h3>
              <Carousel
                className="carouselContainer"
                autoPlay
                interval={5000}
                animation="fade"
                indicators={false}
                swipe
                navButtonsAlwaysVisible
              >
                {renderCarouselItems()}
              </Carousel>
            </Grid>
          )}
        </>
      ) : (
        <Spinner />
      )}
    </Grid>
  );
};

export default ArtworkInfo;
