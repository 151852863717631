import React, { useContext, useEffect, useState } from "react";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Carousel from "react-material-ui-carousel";
import { useNavigate } from "react-router-dom";
// Assets
import border from "../assets/thecolorist_border.png";
import bg from "../assets/thecolorist_bg.jpg";
import Logo from "../assets/The_Colorist.png";
// Context
import ArtworkContext from "../context/artwork/artworkContext";
import ArtistContext from "../context/artist/artistContext";
//Components
import HeaderText from "../components/HeaderText";
import { Typography } from "@mui/material";

const Home = () => {
  const [visibleArtworks, setVisibleArtworks] = useState([]);
  const [visibleArtists, setVisibleArtists] = useState([]);
  let navigate = useNavigate();
  const artworkContext = useContext(ArtworkContext);
  const { getArtworks, artworks } = artworkContext;
  const artistContext = useContext(ArtistContext);
  const { getArtists, artists } = artistContext;

  useEffect(() => {
    artworks?.length==undefined && getArtworks();
    getArtists();
  }, [artists, visibleArtists?.length,artworks?.length]);

  useEffect(() => {
    if (artworks?.length > 0) {
      const slicedArtworks = artworks.slice(0, 7); // Limit to the first 10 artworks
      setVisibleArtworks(slicedArtworks);
    }
    if (artists?.length > 0) {
      const slicedArtists = artists?.slice(0, 10); // Limit to the first 10 artworks
      setVisibleArtists(slicedArtists);
    }
  }, [artworks?.length, artists]);
  return (
   
      <div
        style={{ display: "flex", flexDirection: "column", height: "100vh" }}
      >
        <div style={{ position: "fixed", top: "0vh", width: "100%" }}>
          <Grid
            container
            columns={12}
            style={{
              backgroundImage: `url(${border})`,
              height: "100vh",
              width: "100vw",
              backgroundSize: "cover",
              zIndex: -3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "0.5rem solid #A75000 ",
              padding: "2rem",
            }}
          >
            <Grid
              sx={{
                backgroundImage: `url(${bg})`,
                height: "82vh",
                width: "100%",
                backgroundSize: "cover",
                marginInline: "2rem",
                padding: { xs: "0.5rem", md: "4rem" },
                border: "1rem solid white",
              }}
            >
              <Grid
                container
                columns={12}
                display="flex"
                sx={{ flexDirection: { xs: "column", md: "row" } }}
              >
                <Grid item md={6} xs={12}>
                  <HeaderText onClick={() => navigate("/")}>
                    <p className="one">The </p>
                    <p className="two">Colorist</p>
                  </HeaderText>
                  <Typography
                    variant="body1"
                    color="whitesmoke"
                    sx={{
                      visibility: { md: "visible", xs: "hidden" },
                      height: { md: "auto", xs: "0px" },
                    }}
                  >
                    {/* Empowering Sprouting Artists in the field of Fine Arts. */}
                    {/* <br /> */}
                    <br />
                    Our NGO, The Colorist, was established in April 2023 in
                    Muzaffarnagar, Uttar Pradesh, with a mission to support
                    emerging artists seeking individual recognition. Through
                    diverse programs, workshops, and mentorship, we provide
                    scholastic knowledge and foster professional abilities in
                    these talented artists.
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Grid container columns={12} height="auto">
                    <Grid item md={12} xs={12}>
                      <Carousel
                        className="carouselContainer"
                        autoPlay
                        interval={2000}
                        animation="fade"
                        indicators={false}
                        swipe
                      >
                        {visibleArtists?.map((artist, index, array) => {
                          const startIndex =
                            (index - 1 + array.length) % array.length;
                          const endIndex = (index + 1) % array.length;
                          const artistsToRender = [
                            array[startIndex],
                            array[index],
                            array[endIndex],
                          ];

                          return (
                            <Grid
                              key={index}
                              container
                              display="flex"
                              justifyContent="space-around"
                            >
                              {artistsToRender.map((artistItem, subIndex) => (
                                <Grid
                                  key={subIndex}
                                  item
                                  xs={4}
                                  md={3.5}
                                  display="flex"
                                  justifyContent="center"
                                  className={
                                    subIndex === 1 ? "activeCard" : "cardMedia"
                                  }
                                  height="auto"
                                >
                                  <CardMedia
                                    onClick={() =>
                                      navigate(`/artist/${artistItem?._id}`, {
                                        state: artistItem,
                                      })
                                    }
                                    component="img"
                                    sx={{
                                      width:
                                        subIndex === 1
                                          ? { xs: "25vh", md: "30vh" }
                                          : { xs: "10vh", md: "15vh" },
                                      height:
                                        subIndex === 1
                                          ? { xs: "20vh", md: "28vh" }
                                          : { xs: "10vh", md: "20vh" },
                                      margin: "1vh 1vh",
                                      padding: "0.2rem",
                                      border: "0.5rem solid white",
                                      boxShadow:
                                        "inset 0px 4px 4px rgba(0, 0, 5, 0.4)",
                                      cursor: "pointer",
                                    }}
                                    image={artistItem?.artist_img}
                                    alt={artistItem?.artist_img}
                                    loading="lazy"
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          );
                        })}
                      </Carousel>
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Carousel
                        className="carouselContainer"
                        autoPlay
                        interval={2000}
                        animation="fade"
                        indicators={false}
                        swipe
                      >
                        {visibleArtworks?.map((artwork, index, array) => {
                          const startIndex =
                            (index - 1 + array.length) % array.length;
                          const endIndex = (index + 1) % array.length;
                          const artworksToRender = [
                            array[startIndex],
                            array[index],
                            array[endIndex],
                          ];

                          return (
                            <Grid
                              key={index}
                              container
                              display="flex"
                              justifyContent="space-around"
                            >
                              {artworksToRender?.map(
                                (artworkItem, subIndex) => (
                                  <Grid
                                    key={subIndex}
                                    item
                                    xs={4}
                                    md={3.5}
                                    display="flex"
                                    justifyContent="center"
                                    className={
                                      subIndex === 1
                                        ? "activeCard"
                                        : "cardMedia"
                                    }
                                    height="auto"
                                  >
                                    <CardMedia
                                      onClick={() =>
                                        navigate(
                                          `/artwork/${artworkItem?._id}`,
                                          {
                                            state: artworkItem,
                                          }
                                        )
                                      }
                                      component="img"
                                      sx={{
                                        width:
                                          subIndex === 1
                                            ? { xs: "35vh", md: "30vh" }
                                            : { xs: "20vh", md: "15vh" },
                                        height:
                                          subIndex === 1
                                            ? { xs: "20vh", md: "28vh" }
                                            : { xs: "10vh", md: "20vh" },
                                        margin: "1vh auto",
                                        padding: "0.2rem",
                                        border: "0.5rem solid white",
                                        boxShadow:
                                          "inset 0px 4px 4px rgba(0, 0, 5, 0.4)",
                                        cursor: "pointer",
                                      }}
                                      image={artworkItem?.art_img}
                                      alt={artworkItem?.art_img}
                                      loading="lazy"
                                    />
                                  </Grid>
                                )
                              )}
                            </Grid>
                          );
                        })}
                      </Carousel>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    
  );
};

export default Home;
