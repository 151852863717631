import { useReducer } from "react";
import axios from "axios";
import ArtworkContext from "./artworkContext";
import artworkReducer from "./artworkReducer";
import {
  GET_ARTWORKS,
  ADD_ARTWORK,
  DELETE_ARTWORK,
  SET_CURRENT,
  CLEAR_CURRENT,
  UPDATE_ARTWORK,
  FILTER_ARTWORKS,
  CLEAR_FILTER,
  ARTWORK_ERROR,
  CLEAR_ARTWORKS,
} from "../types";

const ArtworkState = (props) => {
  const initialState = {
    artworks: null,
    current: null,
    filtered: null,
    error: null,
  };
  let url = process.env.REACT_APP_API_URL;

  const [state, dispatch] = useReducer(artworkReducer, initialState);

  // Get Contacts
  const getArtworks = async () => {
    try {
      const res = await axios.get(`${url}/api/artwork`);
      dispatch({
        type: GET_ARTWORKS,
        payload: res?.data,
      });
    } catch (err) {
      console.log("error:",err?.response?.msg);
      dispatch({
        type: ARTWORK_ERROR,
        payload: err?.response?.msg,
      });
    }
  };

  // Add Contact
  const addArtwork = async (artwork) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.post(`${url}/api/artwork`, artwork, config);

      dispatch({
        type: ADD_ARTWORK,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: ARTWORK_ERROR,
        payload: err?.response?.msg,
      });
    }
  };

  // Delete
  const deleteArtwork = async (id) => {

    try {
      await axios.delete(`${url}/api/artwork/${id}`);

      dispatch({
        type: DELETE_ARTWORK,
        payload: id,
      });
    } catch (err) {
      dispatch({
        type: ARTWORK_ERROR,
        payload: err?.response?.msg,
      });
    }
  };

  // Update Contact
  const updateArtwork = async (artwork) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.put(
        `${url}/api/artwork/${artwork._id}`,
        artwork,
        config
      );

      dispatch({
        type: UPDATE_ARTWORK,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: ARTWORK_ERROR,
        payload: err?.response?.msg,
      });
    }
  };

  // Clear Contacts
  const clearArtworks = () => {
    dispatch({ type: CLEAR_ARTWORKS });
  };

  // Set Current Contact
  const setCurrent = (artwork) => {
    dispatch({ type: SET_CURRENT, payload: artwork });
  };

  // Clear Current Contact
  const clearCurrent = () => {
    dispatch({ type: CLEAR_CURRENT });
  };

  // Filter Contacts
  const filterArtworks = (text) => {
    dispatch({ type: FILTER_ARTWORKS, payload: text });
  };

  // Clear Filter
  const clearFilter = () => {
    dispatch({ type: CLEAR_FILTER });
  };

  return (
    <ArtworkContext.Provider
      value={{
        artworks: state.artworks,
        current: state.current,
        filtered: state.filtered,
        error: state.error,
        addArtwork,
        deleteArtwork,
        setCurrent,
        clearCurrent,
        updateArtwork,
        filterArtworks,
        clearFilter,
        getArtworks,
        clearArtworks,
      }}
    >
      {props.children}
    </ArtworkContext.Provider>
  );
};

export default ArtworkState;
