import React from "react";
import Modal from "@mui/material/Modal";
import { Typography, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const AlertModal = ({ openvalue, setOpenValue, message }) => {
  return (
    <Modal
      open={openvalue}
      onClose={() => setOpenValue(false)}
      closeAfterTransition
      style={{
        background: "white",
        width: "90%",
        margin: "auto",
        height: "20vh",
        textAlign: "center",
        padding: "1%",
      }}
    >
      <Grid container columns={12} justifyContent="flex-start">
        <Grid
          item
          md={12}
          textAlign="right"
          onClick={() => setOpenValue(false)}
          style={{ cursor: "pointer" }}
        >
          <CloseIcon />
        </Grid>
        <Grid item md={12} m={4}>
          <Typography variant="h6">{message}</Typography>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AlertModal;
