//Packages
import React from "react";
import Grid from "@mui/material/Grid";
import CardMedia from "@mui/material/CardMedia";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
//Assets
import artworkimg from "../assets/thecolorist_flowers.jpg";
import deovrat from "../assets/Deovrat_Saini.png";
//Component
import HeaderText from "../components/HeaderText";
import breakpoints from "../essentials/screensize";
import NavButton from "../components/NavButton";
import { bgcolor } from "@mui/system";

const FounderImage = styled.div`
  height: 25vh;
  width: 18vw;
  margin: 0rem;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  @media (max-width: ${breakpoints.sm}px) {
    height: 85%;
    width: 100%;
  }
`;

const AboutUs = () => {
  return (
    <Grid
      container
      columns={12}
      display="flex"
      zIndex={1}
      alignItems="stretch" 
      backgroundColor="whitesmoke"
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          backgroundImage: `linear-gradient(rgba(255,255,255, 0.5),rgba(255,255,255, 0.7)), url(${artworkimg})`,
          backgroundSize:'cover',
          backgroundPosition: "center",
          display: "flex",
          backgroundRepeat:'no-repeat',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            textAlign: "left",
            paddingInline: "8%",
            paddingBottom: "5vh",
            paddingTop: "12vh",
          }}
        >
          <br />
          <Grid container columns={12}>
          <Grid item md={12} xs={12}>
          <h3 style={{ fontSize: "2.7em" }}>
            Empowering Sprouting Artists in the field of Fine Arts.
          </h3>
          <br />
          <br />
          <br />

          {/* <br />
              Our NGO, The Colorist, was established in April 2023 in
              Muzaffarnagar, Uttar Pradesh, with a mission to support emerging
              artists seeking individual recognition. Through diverse programs,
              workshops, and mentorship, we provide scholastic knowledge and
              foster professional abilities in these talented artists.
              <br /> */}
          <br />
          Our esteemed Kala Gurus play a crucial role in guiding these artists,
          helping them refine their techniques and discover their unique
          artistic voice. <br />
          <br />
          At The Colorist, we understand the importance of exposure and
          networking for artists. That's why we actively facilitate networking
          and showcase opportunities, such as exhibitions and collaborative
          projects, to enable artists to gain visibility and connect with
          potential patrons and art enthusiasts.
          <br />
          <br />
          Emphasizing social responsibility, we also engage in community
          outreach through art. Our initiatives promote art as a powerful medium
          to address social issues, promote cultural understanding, and
          contribute positively to the communities we serve.</Grid>
        </Grid>

        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={5.98}
        bgcolor="#01337E"
        sx={{
          display: "flex", // Add this line
          flexDirection: "column", // Add this line
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: "#d0d5d9",
            textAlign: "left",
            paddingInline: "8%",
            paddingBottom: "auto",
            paddingTop: "13vh",
          }}
          align="justify"
        >
          <Grid container columns={12}>
            <Grid item md={8} xs={7}>
              <HeaderText>
                <p className="one" style={{ color: "#d0d5d9" }}>
                  Deovrat Saini
                </p>
              </HeaderText>
              <br />
              Founder - The Colorist
              <br />
            </Grid>
            <Grid item md={3} xs={3}>
              <FounderImage>
                <CardMedia component="img" src={deovrat} alt={deovrat} />
              </FounderImage>
            </Grid>
          </Grid>
          <br />
          As a mechanical engineer, my work with steel and other materials
          showcases the precision and technical aspects of my profession.
          However, what truly captivates me is the stark contrast art brings to
          my life with its emotional depth and expressive power. Embracing art
          alongside my technical expertise adds a different dimension to my
          experiences, providing a well-rounded perspective that enriches both
          my personal and professional journey.
          <br />
          <br />
          Art serves as a powerful medium for expression, fostering empathy and
          connection with others' emotions. During difficult times, it offers
          solace through shared human experiences. Art's uniqueness lies in its
          ability to evoke emotions, provoking introspection and
          self-reflection. Engaging with artwork allows me to confront
          overlooked feelings, providing a safe space for emotional exploration
          and healing.
          <br />
          {/* <br />
              Art and engineering integration enhances my creativity and
              empathy. Exploring further, I seek new insights for positive
              change. The fusion of logic and creativity opens infinite
              possibilities, a fulfilling experience. */}
          <Grid container columns={12} mt={5} pb={12} alignItems="flex-end">
            <Grid item md={6} xs={8}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <PhoneAndroidIcon
                  fontSize="small"
                  style={{ color: "#d0d5d9" }}
                />
                <small style={{ marginLeft: "0.2rem", color: "#d0d5d9" }}>
                  +91 9602902907
                </small>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "1rem",
                  alignItems: "center",
                  marginTop: "-0.02rem",
                }}
              >
                <MailOutlineIcon
                  fontSize="small"
                  style={{ color: "#d0d5d9" }}
                />
                <small style={{ marginLeft: "0.2rem", color: "#d0d5d9" }}>
                  deovrat@thecolorist.in
                </small>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "1rem",
                  alignItems: "center",
                  marginTop: "-0.02rem",
                }}
              >
                <MailOutlineIcon
                  fontSize="small"
                  style={{ color: "#d0d5d9" }}
                />
                <small style={{ marginLeft: "0.2rem", color: "#d0d5d9" }}>
                  deovrat73@gmail.com
                </small>
              </div>
            </Grid>
            <Grid
              item
              md={2}
              xs={12}
              sx={{ justifySelf: "right", marginLeft: { md: "auto", xs: 0 } }}
              mr={1}
              mt={2}
            >
              <Link to="/contact-us">
                <NavButton label="Contact Us" />
              </Link>
            </Grid>
          </Grid>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AboutUs;
