import styled from 'styled-components';
import { Button } from "@mui/material";
import colors from '../essentials/colors';

const BlueButton = styled(Button)`
  && {
    background: ${colors.primary};
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    text-align: center;
    padding: 0.2rem;
    margin: auto;
    color: white;
    margin-block-start: 2%;
    margin-inline: 0.5rem;
  }
`;

export default BlueButton;

