//Packages
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
//Assets
import canvasimg from "../assets/thecolorist-canvas.png";
import artistimg from "../assets/artists-thecolorist.png";
import greetingimg from "../assets/thecolorist-greetingcard.png";
import businesscard from "../assets/businesscard-thecolorist.png";
import sizeimg from "../assets/size-thecolorist.png";
import surfaceimg from "../assets/thecolorist-surfaces.png";
import mediumimg from "../assets/medium-thecolorist.png";
import animalia from "../assets/animals-thecolorist.png";
import portraitimg from "../assets/portrait-thecolorist.png";
//Components
import styled from "styled-components";
import InfoCard from "../components/InfoCard";
import breakpoints from "../essentials/screensize";
import { Typography } from "@mui/material";

const ArtistCard = styled(InfoCard)`
  height: 15vh;
  width: 15vh;
  padding: 0.2rem;
  margin-block: 5%;
  @media (max-width: ${breakpoints.sm}px) {
//     height: 30vh;
//   width: 15vh;
    
  }
`;
const OurServices = () => {
  let navigate = useNavigate();
  return (
    <Grid
      container
      columns={12}
      display="flex"
      p={2}
      sx={{ paddingTop: { xs: "10vh", md: "15vh" } }}
    >
      <Typography variant="h6" mb={2}>
        Our diverse skill set makes you capable of taking on a wide range of
        creative projects, from individual commissions to bulk orders and live
        events. This versatility is undoubtedly a valuable asset that can cater
        to a diverse clientele.
      </Typography>
      <Grid item md={6} xs={12}>
        <Grid container columns={12} display="flex" alignItems="center">
          <Grid item md={4} xs={4}>
            <ArtistCard>
              <img src={artistimg} alt="canvas" />
            </ArtistCard>
          </Grid>
          <Grid item md={7} xs={7} ml={1}>
            <h3>Skilled and Versatile Artists</h3>
            <p style={{ marginTop: "3%" }}>
              We represent artists who have wide range of expertise in various
              art forms and mediums who are offering services both at your
              studio and on-location.
            </p>
          </Grid>
        </Grid>
      </Grid>
      {/* 2 */}
      <Grid item md={6} xs={12} mt={3}>
        <Grid container columns={12} display="flex" alignItems="center">
          <Grid item md={4} xs={4}>
            <ArtistCard>
              <img src={canvasimg} alt="canvas" />
            </ArtistCard>
          </Grid>
          <Grid item md={7} xs={7} ml={1}>
            <h3>Live Portrait and Landscaping</h3>
            <p style={{ marginTop: "3%" }}>
              Creating portraits and landscape art in real-time settings,
              capturing the essence of the moment.
            </p>
          </Grid>
        </Grid>
      </Grid>
      {/* 3 */}
      <Grid item md={6} xs={12} mt={3}>
        <Grid container columns={12} display="flex" alignItems="center">
          <Grid item md={4} xs={4}>
            <ArtistCard>
              <img src={greetingimg} alt="canvas" />
            </ArtistCard>
          </Grid>
          <Grid item md={7} xs={7} ml={1}>
            <h3>Greeting Cards and Small Artwork in Bulk</h3>
            <p style={{ marginTop: "3%" }}>
              Producing batches of small artworks and cards for various
              purposes.
            </p>
          </Grid>
        </Grid>
      </Grid>
      {/* 4 */}
      <Grid item md={6} xs={12} mt={3}>
        <Grid container columns={12} display="flex" alignItems="center">
          <Grid item md={4} xs={4}>
            <ArtistCard>
              <img src={businesscard} alt="canvas" />
            </ArtistCard>
          </Grid>
          <Grid item md={7} xs={7} ml={1}>
            <h3>Designing Wedding and Business Cards</h3>
            <p style={{ marginTop: "3%" }}>
              Creating personalized and visually appealing designs for special
              occasions and professional needs.
            </p>
          </Grid>
        </Grid>
      </Grid>
      {/* 5 */}
      <Grid item md={6} xs={12} mt={3}>
        <Grid container columns={12} display="flex" alignItems="center">
          <Grid item md={4} xs={4}>
            <ArtistCard>
              <img
                src="https://res.cloudinary.com/dak47umlr/image/upload/v1690278923/A7_rcc7q8.jpg"
                alt="canvas"
              />
            </ArtistCard>
          </Grid>
          <Grid item md={7} xs={7} ml={1}>
            <h3>Cultural Art Forms</h3>
            <p style={{ marginTop: "3%" }}>
              Proficient in traditional art styles such as Rajasthani,
              Madhubani, Mandala, and Miniature.
            </p>
          </Grid>
        </Grid>
      </Grid>
      {/* 6 */}
      <Grid item md={6} xs={12} mt={3}>
        <Grid container columns={12} display="flex" alignItems="center">
          <Grid item md={4} xs={4}>
            <ArtistCard>
              <img
                src="https://res.cloudinary.com/the-perception/image/upload/v1682272322/IMG_20230423_231353.jpg.jpg"
                alt="canvas"
              />
            </ArtistCard>
          </Grid>
          <Grid item md={7} xs={7} ml={1}>
            <h3>Landscape / Cityscape / Village Scenery</h3>
            <p style={{ marginTop: "3%" }}>
              Capturing various aspects of natural and urban environments.
            </p>
          </Grid>
        </Grid>
      </Grid>
      {/* 7 */}
      <Grid item md={6} xs={12} mt={3}>
        <Grid container columns={12} display="flex" alignItems="center">
          <Grid item md={4} xs={4}>
            <ArtistCard>
              <img src={sizeimg} alt="sizeimg-thecolorist" />
            </ArtistCard>
          </Grid>
          <Grid item md={7} xs={7} ml={1}>
            <h3>Working with Standard and Nonstandard Sizes</h3>
            <p style={{ marginTop: "3%" }}>
              Adapting creations to a variety of dimensions, from small cards to
              large canvas sizes.
            </p>
          </Grid>
        </Grid>
      </Grid>
      {/* 8 */}
      <Grid item md={6} xs={12} mt={3}>
        <Grid container columns={12} display="flex" alignItems="center">
          <Grid item md={4} xs={4}>
            <ArtistCard>
              <img src={surfaceimg} alt="surfaceimg-thecolorist" />
            </ArtistCard>
          </Grid>
          <Grid item md={7} xs={7} ml={1}>
            <h3>Artwork on Various Surfaces</h3>
            <p style={{ marginTop: "3%" }}>
              Skilled in working with canvas, canvas board, handmade paper,
              fabrics, glass, tiles, and wood.
            </p>
          </Grid>
        </Grid>
      </Grid>
      {/* 9 */}
      <Grid item md={6} xs={12} mt={3}>
        <Grid container columns={12} display="flex" alignItems="center">
          <Grid item md={4} xs={4}>
            <ArtistCard>
              <img src={mediumimg} alt="mediumimg-thecolorist" />
            </ArtistCard>
          </Grid>
          <Grid item md={7} xs={7} ml={1}>
            <h3>Artwork with Various Mediums</h3>
            <p style={{ marginTop: "3%" }}>
              Experienced in using oil, acrylic, water colors, poster colors,
              Oil pastels, charcoal, and pencil.
            </p>
          </Grid>
        </Grid>
      </Grid>
      {/* 10 */}
      <Grid item md={6} xs={12} mt={3}>
        <Grid container columns={12} display="flex" alignItems="center">
          <Grid item md={4} xs={4}>
            <ArtistCard>
              <img src={animalia} alt="animalia-thecolorist" />
            </ArtistCard>
          </Grid>
          <Grid item md={7} xs={7} ml={1}>
            <h3>Specific Art of Flowers / Birds / Animals</h3>
            <p style={{ marginTop: "3%" }}>
              Creating intricate and expressive artworks featuring flora and
              fauna.
            </p>
          </Grid>
        </Grid>
      </Grid>
      {/* 11 */}
      <Grid item md={6} xs={12} mt={3}>
        <Grid container columns={12} display="flex" alignItems="center">
          <Grid item md={4} xs={4}>
            <ArtistCard>
              <img src={portraitimg} alt="portrait-thecolorist" />
            </ArtistCard>
          </Grid>
          <Grid item md={7} xs={7} ml={1}>
            <h3>Human Portraits</h3>
            <p style={{ marginTop: "3%" }}>
              Creating detailed and lifelike portraits of individuals.
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OurServices;
