//Packages
import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useForm, ValidationError } from "@formspree/react";
//Assets
// import SubmitButton from "../components/button/SubmitButton";
import { Button, Typography } from "@mui/material";
import AlertModal from "./AlertModal";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });
  const [state, handleSubmit] = useForm("xjvqlpqb");
  const [openmodal, setOpenModal] = useState(false);
  useEffect(() => {
    if (state.succeeded) {
      setOpenModal(true);
      setFormData({
        name: "",
        email: "",
        phoneNumber: "",
        query: "",
      });
    }
  }, [state.succeeded]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <Grid
        container
        columns={12}
        backgroundColor="whitesmoke"
        zIndex={1}
        alignItems="center"
        padding="2%"
        style={{ height: "100vh" }}
      >
        <Grid
          item
          md={6}
          xs={12}
          style={{ paddingBlock: "2%", paddingInline: "8%" }}
        >
          <Typography variant="h3" style={{ fontFamily: "Brush Script MT" }}>
            Send your query in the form,
          </Typography>
          <h3>We'll get back to you shortly</h3>
        </Grid>
        <Grid item md={6} xs={12} p={2}>
          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              height: "50vh",
            }}
          >
            <Grid
              container
              columns={12}
              display="flex"
              flexDirection="column"
              marginInline="auto"
              borderRadius="5px"
              textAlign="left"
              padding="3rem"
            >
              <div>
                <div className="inputdiv">
                  <label>Name *</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="inputdiv">
                  <label>Email *</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="inputdiv">
                  <label>Phone Number *</label>
                  <input
                    type="tel"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                    pattern="[0-9]{10}"
                  />
                </div>
                <div className="inputdiv">
                  <label>Query </label>
                  <textarea
                    rows="4"
                    cols="50"
                    name="query"
                    value={formData.query}
                    onChange={handleChange}
                  />
                </div>
                <div className="inputdiv">
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ marginBlock: "2vh", background: "#01337E" }}
                  >
                    <b>Submit</b>
                  </Button>
                </div>
                <ValidationError
                  prefix="Message"
                  field="message"
                  errors={state.errors}
                />
              </div>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <AlertModal
        openvalue={openmodal}
        setOpenValue={setOpenModal}
        message="Your query has been sent successfully!"
      />
    </>
  );
}

export default ContactUs;
