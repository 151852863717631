import { useState, useEffect } from "react";

const ProgressiveImage = ({ placeholder, src, alt }) => {
  const [currentImage, setCurrentImage] = useState(placeholder);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fullImage = new Image();
    fullImage.src = src;
    fullImage.onload = () => {
      setIsLoading(false);
      setCurrentImage(fullImage.src);
    };
  }, [src]);

  return (
    <img
      src={currentImage}
      alt={alt}
      className={isLoading ? "loading" : "loaded"}
      style={{
        width: { xs: "100%", md: "100%" },
        height: { xs: "100%", md: "20vh" },
        objectFit: "contain",
        marginInline: "auto",
        padding: "0.2em",
        border: "0.5rem solid white",
        boxShadow: "inset 0px 4px 4px rgba(0, 0, 5, 0.4)",
        objectFit: "contain",
      }}
    />
  );
};

export default ProgressiveImage;
