//Packages
import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Carousel from "react-material-ui-carousel";
import { useNavigate } from "react-router-dom";
//Components
import HeadingBanner from "../components/HeadingBanner";
import BlueButton from "../components/BlueButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ProgressiveImage from "../components/ProgressiveImage";
//Assets
import loader from "../assets/loader.gif";
import Logo from "../assets/The_Colorist.png";
// Context
import ArtworkContext from "../context/artwork/artworkContext";
import Spinner from "../components/Spinner";

const Artworks = () => {
  let navigate = useNavigate();
  let acrylic_artworks = [];
  let illustration_artworks = [];

  const artworkContext = useContext(ArtworkContext);
  const { getArtworks, artworks } = artworkContext;
  const [acrylicArtworks, setAcrylicArtworks] = useState([]);
  const [illustrationArtworks, setIllustrationArtworks] = useState([]);

  // const findAcrylicArtworks = async () => {
  //   await artworks?.map(
  //     (art) => art?.art_type == "acrylic" && acrylic_artworks.push(art)
  //   );
  //   if (acrylic_artworks?.length > 0) {
  //     const slicedArtworks = await acrylic_artworks?.slice(0, 10); // Limit to the first 5 artworks
  //     await setAcrylicArtworks(slicedArtworks);
  //   }
  // };
  const renderCarouselItems = () => {
    const isMobileView = window.innerWidth < 600; // Adjust the breakpoint as per your needs
    const itemsToShow = isMobileView ? 1 : 4;

    return artworks?.map((artist, index, array) => {
      const artworksToRender = [...array, ...array.slice(0, itemsToShow - 1)];
      return (
        <Grid key={index} container display="flex">
          {artworksToRender
            ?.slice(index, index + itemsToShow)
            .map((artworkItem, subIndex) => (
              <Grid
                key={subIndex}
                item
                xs={12}
                md={2}
                display="flex"
                className="activeArtCard"
                m="auto"
                justifyContent="center"
                onClick={() =>
                  navigate(`/artwork/${artworkItem?._id}`, {
                    state: artworkItem,
                  })
                }
                style={{ cursor: "pointer" }}
              >
                <ProgressiveImage
                  placeholder={loader}
                  src={artworkItem?.art_img}
                  alt={artworkItem?.art_img}
                />
              </Grid>
            ))}
        </Grid>
      );
    });
  };
  // const findIllustrationArtworks = async () => {
  //   await artworks?.map(
  //     (art) =>
  //       art?.art_type == 'illustration' && illustration_artworks.push(art)
  //   );
  //   if (illustration_artworks?.length > 0) {
  //     const slicedArtworks = await illustration_artworks?.slice(0, 10); // Limit to the first 5 artworks
  //     await setIllustrationArtworks(slicedArtworks);
  //   }
  // };
  useEffect(() => {
    getArtworks();
    // findAcrylicArtworks();
    // findIllustrationArtworks();
  }, [artworks, acrylicArtworks?.length]);
  return (
    <Grid
      container
      columns={12}
      display="flex"
      sx={{ paddingTop: { xs: "0vh", md: "15vh" } }}
    >
      {artworks?.length !== undefined ? (
        <Grid container columns={12} style={{ minHeight: "fit-content" }}>
          <Grid item md={6} xs={6} sx={{ marginTop: { xs: "12vh", md: "0%" } }}>
            <HeadingBanner label="Artworks" />
          </Grid>
          {/* subcategory row */}
          {/* <Grid item md={12} mb={1.5}>
        <BlueButton>Acrylic</BlueButton>
      </Grid> */}
          <Grid item md={12} mt={6} xs={12} mb={6}>
            {artworks?.length !== 0 ? (
              <Carousel
                className="carouselContainer"
                autoPlay
                interval={5000}
                animation="fade"
                indicators={false}
                swipe
                navButtonsAlwaysVisible
              >
                {renderCarouselItems()}
              </Carousel>
            ) : (
              <Spinner />
            )}
          </Grid>
          {/* <Grid item md={12} mb={1.5} >
          <BlueButton>Illustration</BlueButton>
        </Grid>
        <Grid item md={12}>
          <Carousel
            className="carouselContainer"
            autoPlay
            interval={5000}
            animation="fade"
            indicators={false}
            navButtonsAlwaysVisible
            swipe
          >
            {illustrationArtworks?.map((artwork, index) => (
              <Grid
                key={index}
                container
                spacing={1}
                display="flex"
                className="carouselslide"
              >
                {illustrationArtworks
                  ?.slice(index, index + 4)
                  .map((artworkItem, subIndex) => (
                    <Grid
                      key={subIndex}
                      item
                      xs={12}
                      md={2.8}
                      display="flex"
                      className="activeCard"
                      m="auto"
                      justifyContent="center"
                    >
                      <CardMedia
                        onClick={() =>
                          navigate(`/artwork/${artworkItem?.art_title}`, {
                            state: artworkItem,
                          })
                        }
                        component="img"
                        sx={{
                          width: { xs: "100%", md: "20vh" },
                          height: { xs: "100%", md: "20vh" },
                          marginInline: "1rem",
                          padding: "0.2em",
                          border: "0.5rem solid white",
                          boxShadow: "inset 0px 4px 4px rgba(0, 0, 5, 0.4)",
                        }}
                        className="hovericon"
                        image={artworkItem?.art_img}
                        alt={artworkItem?.art_img}
                        loading="lazy"
                      />
                    </Grid>
                  ))}
              </Grid>
            ))}
          </Carousel>
        </Grid> */}
        </Grid>
      ) : (
        <Spinner />
      )}
    </Grid>
  );
};

export default Artworks;
