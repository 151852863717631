//Packages
import React from "react";
import Grid from "@mui/material/Grid";
import CardMedia from "@mui/material/CardMedia";
//Components
import HeadingBanner from "../components/HeadingBanner";
//Assets
import eventimg from "../assets/thecolorist_bg.jpg";
import { Typography } from "@mui/material";

const LordGaneshaExhibition = ({ eventtheme }) => {
  return (
    <>
      <Grid item md={6} xs={12}>
        <HeadingBanner label={eventtheme} />
        <Typography style={{ paddingInline: "1.5rem", marginBlock: "2%" }}>
          <b style={{ fontSize: "20px" }}>
            वक्र तुंड महाकाय, सूर्य कोटि समप्रभ:। <br /> निर्विघ्नं कुरु मे देव
            शुभ कार्येषु सर्वदा।। <br />
          </b>{" "}
          घुमावदार सूंड वाले, विशाल शरीर काय, करोड़ सूर्य के समान महान
          प्रतिभाशाली । <br />
          मेरे प्रभु, हमेशा मेरे सारे कार्य बिना विघ्न के पूरे करें (करने की
          कृपा करें)॥ <br />
          <br />
          On the
          <b style={{ fontSize: "20px" }}> auspicious </b>
          occassion of Diwali 2023,
          <b style={{ fontSize: "20px" }}> The Colorist </b>organised the art
          exhibition.
          <br /> <br />
          Ganesh Ji, the Hindu God, is revered as the remover of obstacles and
          patron of arts, sciences, intellect, and wisdom, worshipped first in
          all major endeavors as he paves the path to success. He is the
          guardian of esoteric knowledge, revered by transcendentalists,
          depicted with a distinguished body and an elephant head, and widely
          worshipped. Ganesh Ji's worship is believed to bring prosperity,
          success, and protection. In yoga, he is associated with the root
          chakra, and the "Ganesh Mudra" motivates practitioners to persevere in
          any circumstance.
        </Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
        paddingLeft="auto"
        justifyContent="center"
        textAlign="center"
      >
        <video autoPlay controls loop height='80%' >
          <source
            src={
              "https://res.cloudinary.com/dak47umlr/video/upload/v1700712331/TheColorist_Ganesha_Exhibition_ux9jvt.mp4"
            }
            type="video/mp4"
          />
        </video>
      </Grid>
      {/* <Grid
        item
        md={12}
        sx={{ paddingRight: { xs: "3%", md: "3%" } }}
        mb={4}
        xs={12}
      >
        <Grid container columns={12}>
          <Grid item md={4} xs={4}>
            <CardMedia
              component="img"
              sx={{
                width: { xs: "100%", md: "28vh" },
                height: { xs: "20vh", md: "38vh" },
                marginLeft: "auto",
                padding: "0.2em",
                border: "0.5rem solid white",
                boxShadow: "inset 0px 4px 4px rgba(0, 0, 5, 0.4)",
                objectFit: "contain",
              }}
              className="hovericon"
              image="https://res.cloudinary.com/dak47umlr/image/upload/v1687790696/CWVT9340_vp79pa.jpg"
              alt={eventimg}
            />
          </Grid>
          <Grid item md={4} xs={4}>
            <CardMedia
              component="img"
              sx={{
                width: { xs: "100%", md: "28vh" },
                height: { xs: "20vh", md: "38vh" },
                marginLeft: "auto",
                padding: "0.2em",
                border: "0.5rem solid white",
                boxShadow: "inset 0px 4px 4px rgba(0, 0, 5, 0.4)",
                objectFit: "contain",
              }}
              className="hovericon"
              image="https://res.cloudinary.com/dak47umlr/image/upload/v1687790681/KPVZ4266_dmabkz.jpg"
              alt={eventimg}
            />
          </Grid>
          <Grid item md={4} xs={4}>
            <CardMedia
              component="img"
              sx={{
                width: { xs: "100%", md: "28vh" },
                height: { xs: "20vh", md: "38vh" },
                marginLeft: "auto",
                padding: "0.2em",
                border: "0.5rem solid white",
                boxShadow: "inset 0px 4px 4px rgba(0, 0, 5, 0.4)",
                objectFit: "contain",
              }}
              className="hovericon"
              image="https://res.cloudinary.com/dak47umlr/image/upload/v1692685297/Picture24_uupcca.jpg"
              alt={eventimg}
            />
          </Grid>
        </Grid>
        <Grid container columns={12} mt={2}>
          <Grid item md={4} xs={4}>
            <CardMedia
              component="img"
              sx={{
                width: { xs: "100%", md: "28vh" },
                height: { xs: "15vh", md: "38vh" },
                marginLeft: "auto",
                padding: "0.2em",
                border: "0.5rem solid white",
                boxShadow: "inset 0px 4px 4px rgba(0, 0, 5, 0.4)",
                objectFit: "contain",
              }}
              className="hovericon"
              image="https://res.cloudinary.com/dak47umlr/image/upload/v1692685297/Picture18_mtky1k.jpg"
              alt={eventimg}
            />
          </Grid>
          <Grid item md={4} xs={4}>
            <CardMedia
              component="img"
              sx={{
                width: { xs: "100%", md: "28vh" },
                height: { xs: "15vh", md: "38vh" },
                marginLeft: "auto",
                padding: "0.2em",
                border: "0.5rem solid white",
                boxShadow: "inset 0px 4px 4px rgba(0, 0, 5, 0.4)",
                objectFit: "contain",
              }}
              className="hovericon"
              image="https://res.cloudinary.com/dak47umlr/image/upload/v1692685297/Picture23_nrexop.jpg"
              alt={eventimg}
            />
          </Grid>
          <Grid item md={4} xs={4}>
            <CardMedia
              component="img"
              sx={{
                width: { xs: "100%", md: "28vh" },
                height: { xs: "15vh", md: "38vh" },
                marginLeft: "auto",
                padding: "0.2em",
                border: "0.5rem solid white",
                boxShadow: "inset 0px 4px 4px rgba(0, 0, 5, 0.4)",
                objectFit: "contain",
              }}
              className="hovericon"
              image="https://res.cloudinary.com/dak47umlr/image/upload/v1692685297/Picture20_iyq2yk.jpg"
              alt={eventimg}
            />
          </Grid>
        </Grid>
      </Grid> */}
    </>
  );
};

export default LordGaneshaExhibition;
