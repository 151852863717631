//Packages
import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Carousel from "react-material-ui-carousel";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
//Components
import HeadingBanner from "../components/HeadingBanner";
// import BlueButton from "../components/BlueButton";
import Spinner from "../components/Spinner";
import ProgressiveImage from "../components/ProgressiveImage";
//Assets
import loader from "../assets/loader.gif";
import Logo from "../assets/The_Colorist.png";
// Context
import ArtistContext from "../context/artist/artistContext";

const Artists = () => {
  let navigate = useNavigate();
  const artistContext = useContext(ArtistContext);
  const { getArtists, artists } = artistContext;
  const [handmadeArtists, setHandmadeArtists] = useState([]);

  const renderCarouselItems = () => {
    const isMobileView = window.innerWidth < 600;
    const itemsToShow = isMobileView ? 1 : 4;

    return artists?.map((artist, index, array) => {
      const artistsToRender = [...array, ...array.slice(0, itemsToShow - 1)];

      return (
        <Grid key={index} container display="flex">
          {artistsToRender
            ?.slice(index, index + itemsToShow)
            .map((artistItem, subIndex) => (
              <Grid
                key={subIndex}
                item
                xs={12}
                md={2}
                display="flex"
                className="activeCard"
                m="auto"
                justifyContent="center"
                onClick={() =>
                  navigate(`/artist/${artistItem?._id}`, {
                    state: artistItem,
                  })
                }
                style={{ cursor: "pointer" }}
              >
                <ProgressiveImage
                  placeholder={loader}
                  src={artistItem?.artist_img}
                  alt={artistItem?.artist_img}
                />
              </Grid>
            ))}
        </Grid>
      );
    });
  };
  const findHandmadeArtists = async () => {
    if (artists?.length > 0) {
      const slicedArtists = await artists?.slice(0, 10);
      await setHandmadeArtists(slicedArtists);
    }
  };
  useEffect(() => {
    getArtists();
    findHandmadeArtists();
  }, [artists, handmadeArtists?.length]);
  return (
    <Grid
      container
      columns={12}
      display="flex"
      sx={{ paddingTop: { xs: "0vh", md: "15vh" } }}
    >
      {artists?.length !== undefined ? (
        <Grid container columns={12} style={{ minHeight: "fit-content" }}>
          <Grid item md={6} xs={6} sx={{ marginTop: { xs: "12vh", md: "0%" } }}>
            <HeadingBanner label="Artists" />
          </Grid>
          {/* subcategory row */}
          {/* <Grid item md={12} mb={6}>
        <BlueButton>Handmade</BlueButton>
      </Grid> */}
          <Grid item md={12} mt={6} xs={12} mb={6}>
            {artists?.length !== 0 ? (
              <Carousel
                className="carouselContainer"
                autoPlay
                interval={5000}
                animation="fade"
                indicators={false}
                swipe
                navButtonsAlwaysVisible
              >
                {renderCarouselItems()}
              </Carousel>
            ) : (
              <Spinner />
            )}
          </Grid>
        </Grid>
      ) : (
        <Spinner />
      )}
    </Grid>
  );
};

export default Artists;
