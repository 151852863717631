import React from "react";
import Grid from "@mui/material/Grid";

const Footer = () => {
  return (
    <Grid
      container
      columns={12}
      style={{
        fontSize: "12px",
        color: "#8e8e8e",
        marginTop: "-2vh",
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        paddingRight: "1rem",
      }}
    >
      <Grid
        item
        md={3}
        xs={12}
        sx={{
          textAlign: { md: "left", xs: "center" },
          fontSize: "10px",
        }}
      >
        © 2023 - 2024 The Colorist. All rights reserved.
      </Grid>
      <Grid
        item
        md={4}
        xs={12}
        sx={{
          justifyContent: "flex-end",
          width: "100%",
          paddingRight: "1rem",
          textAlign: { md: "right", xs: "center" },
          fontSize: "10px",
        }}
      >
        Site designed and developed by Sabhya Saini
      </Grid>
    </Grid>
  );
};

export default Footer;
