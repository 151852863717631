//Packages
import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CardMedia from "@mui/material/CardMedia";
import Carousel from "react-material-ui-carousel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
//Components
import HeadingBanner from "../components/HeadingBanner";
import ProgressiveImage from "../components/ProgressiveImage";
import InfoCard from "../components/InfoCard";
import colors from "../essentials/colors";
import styled from "styled-components";
import breakpoints from "../essentials/screensize";
//Assets
import loader from "../assets/loader.gif";
import Logo from "../assets/The_Colorist.png";
// Context
import ArtworkContext from "../context/artwork/artworkContext";
import ArtistContext from "../context/artist/artistContext";
import Spinner from "../components/Spinner";

const ArtistCard = styled(InfoCard)`
  width: 30vh;

  @media (max-width: ${breakpoints.sm}px) {
    width: 30vh;
  }
`;
const ArtistInfo = () => {
  let urlparam = useParams();
  const navigate = useNavigate();
  const [artist, setArtist] = useState();
  const [showAll, setShowAll] = useState(false);
  const artworkContext = useContext(ArtworkContext);
  const { getArtworks, artworks } = artworkContext;
  const artistContext = useContext(ArtistContext);
  const { getArtists, artists } = artistContext;
  const [visibleArtworks, setVisibleArtworks] = useState([]);
  let artist_artworks = [];
  useEffect(() => {
    getArtworks();
    getArtists();
    artists?.map((artist) => artist?._id == urlparam?.id && setArtist(artist));
  }, [urlparam?.id, artists?.length]);
  const findArtistsArtworks = async () => {
    await artworks?.map(
      (artwork) =>
        artwork?.artist == artist?._id && artist_artworks.push(artwork)
    );
    if (artist_artworks?.length > 0) {
      const slicedArtworks = await artist_artworks?.slice(0, 100); // Limit to the first 5 artworks
      await setVisibleArtworks(slicedArtworks);
    }
  };
  const renderCarouselItems = () => {
    const isMobileView = window.innerWidth < 600;
    const itemsToShow = isMobileView ? 1 : 4;

    return visibleArtworks?.map((artist, index, array) => {
      const artworksToRender = [...array, ...array.slice(0, itemsToShow - 1)];

      return (
        <Grid key={index} container columns={12} display="flex">
          {artworksToRender
            ?.slice(index, index + itemsToShow)
            .map((artworkItem, subIndex) => (
              <Grid
                key={subIndex}
                item
                xs={12}
                md={2}
                display="flex"
                className="activeCard"
                m="auto"
                justifyContent="center"
                onClick={() =>
                  navigate(`/artwork/${artworkItem?._id}`, {
                    state: artworkItem,
                  })
                }
                style={{ cursor: "pointer" }}
              >
                <ProgressiveImage
                  placeholder={loader}
                  src={artworkItem?.art_img}
                  alt={artworkItem?.art_img}
                />
              </Grid>
            ))}
        </Grid>
      );
    });
  };
  const LongText = ({ content, limit }) => {
    // const [showAll, setShowAll] = useState(false);

    const showMore = () => setShowAll(true);
    const showLess = () => setShowAll(false);

    if (content?.length <= limit) {
      // there is nothing more to show
      return { content };
    }
    if (showAll) {
      // We show the extended text and a link to reduce it
      return (
        <>
          {content?.length !== undefined && content}
          {content?.length !== undefined && (
            <small
              style={{ color: "#757575", cursor: "pointer" }}
              onClick={() => showLess()}
            >
              Read less
            </small>
          )}
        </>
      );
    }
    // In the final case, we show a text with ellipsis and a `Read more` button
    const toShow = content?.substring(0, limit) + "...";
    return (
      <>
        {content?.length !== undefined && toShow}
        {content?.length !== undefined && (
          <small
            style={{ color: "#757575", cursor: "pointer" }}
            onClick={() => showMore()}
          >
            Read more
          </small>
        )}
      </>
    );
  };
  useEffect(() => {
    findArtistsArtworks();
  }, [artworks]);

  return (
    <Grid
      container
      columns={12}
      display="flex"
      sx={{ paddingTop: { xs: "0vh", md: "15vh" } }}
    >
      {artist ? (
        <>
          <Grid container columns={12} style={{ minHeight: "fit-content" }}>
            <Grid
              item
              md={6}
              xs={12}
              sx={{ marginTop: { xs: "10vh", md: "0%" } }}
            >
              <HeadingBanner label={artist?.name} />
              <Grid
                item
                md={12}
                xs={12}
                display="flex"
                p={2}
                sx={{ flexDirection: "column" }}
              >
                <Typography variant="body2">
                  <LongText content={artist?.about} limit={50} />
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
              sx={{
                paddingRight: {
                  xs: "0rem",
                  md: "5rem",
                },
                paddingLeft: {
                  xs: "0rem",
                  md: "auto",
                },
                textAlign: {
                  xs: "center",
                  md: "right",
                },
              }}
            >
              <ArtistCard>
                <CardMedia
                  component="img"
                  image={artist?.artist_img}
                  alt={artist?.artist_img}
                  height="auto"
                  style={{ width: "100%" }}
                />
              </ArtistCard>
            </Grid>
          </Grid>
          {visibleArtworks?.length > 1 ? (
            <Grid item md={12} xs={12} mb={2} pl={1}>
              <h3>Artist's Artworks</h3>

              <Carousel
                className="carouselContainer"
                autoPlay
                interval={5000}
                animation="fade"
                indicators={false}
                swipe
                navButtonsAlwaysVisible
              >
                {renderCarouselItems()}
              </Carousel>
            </Grid>
          ) : (
            <Grid item md={12} xs={12} mb={2} pl={1}>
              <h3>Artist's Artworks</h3>
              {visibleArtworks?.map((art) => (
                <Grid key={art?._id} container columns={12}>
                  <Grid
                    item
                    xs={12}
                    md={2}
                    display="flex"
                    className="activeCard"
                    m="auto"
                    justifyContent="center"
                    onClick={() =>
                      navigate(`/artwork/${art?._id}`, {
                        state: art,
                      })
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <ProgressiveImage
                      placeholder={loader}
                      src={art?.art_img}
                      alt={art?.art_img}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
        </>
      ) : (
        <Spinner />
      )}
    </Grid>
  );
};

export default ArtistInfo;
